/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


$(document).ready(function(){
  $(".card_package").css("border-bottom-right-radius", "2rem");
  $(".card_package").css("border-bottom-left-radius", "2rem");
  var zindex = 10;

$(".card_package").click(function(e){
  e.preventDefault();

  var isShowing = false;

  if ($(this).hasClass("show")) {
      isShowing = true
  }

  if ($(".cards_packages").hasClass("showing")) {
      
      // a card is already in view
      $(".card_package.show")
          .removeClass("show");

      if (isShowing) {
          // this card was showing - reset the grid
          $(".cards_packages")
          .removeClass("showing");
          $(".card_package").css("border-bottom-right-radius", "2rem");
          $(".card_package").css("border-bottom-left-radius", "2rem");
      } else {
          // this card isn't showing - get in with it
          $(this)
          .css({zIndex: zindex})
          .addClass("show");
      }
      zindex++;
  } else {
      $(".card_package").css("border-bottom-right-radius", "0rem");
      $(".card_package").css("border-bottom-left-radius", "0rem");
      // no cards in view
      $(".cards_packages")
          .addClass("showing");
      $(this)
          .css({zIndex:zindex})
          .addClass("show");
      zindex++;
  }
});
});
$('.btn-package-detail').click(function(){
  window.location = $(this).attr('href');
});

